<template>
    <section v-if="initiationId">
        <div id="not-created-warning-job-vacancy-v2" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="closeModal"/>
                <div class="uk-modal-header uk-flex-inline uk-width-1-1">
                    <img
                        src="/images/warning-red.svg"
                        alt="Boost"
                        style="border-radius: 100%; padding: 1px; max-width: 40px; height: 40px; aspect-ratio: 1/1;"
                    />
                    <h2 class="uk-modal-title header-font">Warning</h2>
                </div>
                <section class="uk-modal-body" style="padding: 20px;">
                    <p>
                        Unable to create job post because "worker request due date" is expired.<br/>
                        Before create this job post, please update the "worker request due date" and "status" on MABES.
                    </p>
                </section>
                <div class="uk-modal-footer justify-end">
                    <button
                        class="uk-button text-white uk-button-primary"
                        @click="closeModal"
                    >
                        <template>
                            Okay
                        </template>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "ModalNotCreatedWarningJobVacancy",
    props: {
        initiationId: {
            type: String,
            default: null,
        },
    },
    methods: {
        closeModal(timeout) {
            const params = [];
            if (timeout > 0) params.push(timeout);

            window.UIkit.modal("#not-created-warning-job-vacancy-v2").$destroy(true);
            this.$emit("cancel", ...params);
        },
    },
};
</script>
<style scoped>
.btn-disabled {
    background-color: #979797;
    pointer-events: none;
}

.text-white {
    color: #fff !important;
}

.justify-end {
    display: flex;
    justify-content: flex-end;
}

.header-font {
    font-size: 24px;
    line-height: 1.5;
    margin: 0 16px;
    letter-spacing: -0.2px;
}
</style>
